import React from "react";
import MainLayout from "ui/Layout";
import { RouteComponentProps } from "@reach/router";
import NavMenuTop from "./components/NavMenuTop";
import JobDetails from "./JobDetails";
import axios from "axios";
import { useAuthToken } from "hooks/useAuthToken";

interface JobDetailsPageProps extends RouteComponentProps {
  jobId?: string;
}

const Overview: React.FC<JobDetailsPageProps> = ({ jobId }) => {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState(null);
  const [authToken] = useAuthToken();

  React.useEffect(() => {
    if (jobId) {
      axios
        .get("/api/v1/joboffers/public/jobid/" + jobId, {
          headers: {
            ...(Boolean(authToken)
              ? { Authorization: `Bearer ${authToken}` }
              : {}),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);

            setData(res.data);
          }
        });
    }
  }, [jobId]);

  return (
    <MainLayout top={<NavMenuTop />}>
      <div className="container">
        <br />
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li>
                  <a
                    style={{
                      color: "#000",
                      fontSize: 16,
                      fontWeight: 400,
                      paddingRight: 10,
                    }}
                    href="/stage-et-emploi/offres"
                  >
                    {" "}
                    <i className="fas fa-long-arrow-alt-left"></i> Retour
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/">Accueil</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/stage-et-emploi">Stage et emploi</a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <aside id="fh5co-course" style={{ paddingTop: "0px" }}>
        {loading ? (
          <div className="text-center">
            <br />
            <div
              className="spinner-grow"
              style={{ width: "4rem", height: "4rem" }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
            <br />
          </div>
        ) : data ? (
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <br />
                <JobDetails job={data} />
              </div>
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <br />
                <div className="card-simple text-center">
                  <br />
                  <img src="/images/search-empty-icon.png" alt="" />
                  <p>
                    <br />
                    <h4>Aucune offre disponible</h4>
                  </p>
                  <a
                    href="/stage-et-emploi/offres"
                    className="btn btn-primary button-top-menu-left"
                  >
                    Toutes les offres d'emploi
                  </a>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        )}
      </aside>
    </MainLayout>
  );
};

export default Overview;

import React from "react";
import MainLayout from "ui/Layout";
import { RouteComponentProps } from "@reach/router";
import NavMenuTopE from "./components/NavMenuTopE";
import TopJobOffers from "./TopJobOffers";
import axios from "axios";
import Covers from "pages/Covers";
import JobsAdsPricing from "pages/Overview/Advertisers/Jobs2";

const Overview: React.FC<RouteComponentProps> = () => {
  const [page, setPage] = React.useState(null);

  React.useEffect(() => {

    axios('/api/v1/tutoring/page-text/12')
      .then(res => {
        setPage(res.data)
      })
  }, []);

  return (
    <MainLayout
      top={<NavMenuTopE />}
    >
      <Covers page="joboffers_entreprise" />

      <div className="container">
        <br />
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/">Accueil</a></li>
                <li className="breadcrumb-item"><a href="/stage-et-emploi">Stage et emploi</a></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <aside id="fh5co-course">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div dangerouslySetInnerHTML={{ __html: page?.presentation }}></div>


              <br />
              <JobsAdsPricing />
            </div>
            <div className="col-md-4">
              <TopJobOffers />
            </div>
          </div>
        </div>
      </aside>
    </MainLayout>
  )
}

export default Overview;
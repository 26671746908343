import React from "react";
import MainLayout from "ui/Layout";
import { RouteComponentProps } from "@reach/router";
import NavMenuTopP from "./components/NavMenuTopP";
import TopJobOffers from "./TopJobOffers";
import axios from "axios";
import Covers from "pages/Covers";

const Overview: React.FC<RouteComponentProps> = () => {
  const [covers, setCovers] = React.useState<Array<any>>([]);
  const [page, setPage] = React.useState(null);


  React.useEffect(() => {
    axios('/api/v1/page-covers?page=joboffers_candidat')
      .then(res => {
        setCovers(res.data)
      });

    axios('/api/v1/tutoring/page-text/11')
      .then(res => {
        setPage(res.data)
      })
  }, []);

  return (
    <MainLayout
      top={<NavMenuTopP />}
    >
      <Covers page="joboffers_candidat" />

      <div className="container">
        <br />
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/">Accueil</a></li>
                <li className="breadcrumb-item"><a href="/stage-et-emploi">Stage et emploi</a></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <aside id="fh5co-course">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div dangerouslySetInnerHTML={{ __html: page?.presentation }}></div>
            </div>
            <div className="col-md-4">
              <TopJobOffers />
            </div>
          </div>
        </div>
      </aside>

    </MainLayout>
  )
}

export default Overview;
import React from "react";
import Routes from "./pages/Routes";
import './App.css';
import { LocationProvider } from "@reach/router";
import { CookiesProvider } from "react-cookie";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <CookiesProvider>
      <LocationProvider>
        <Routes />
        <ToastContainer
          position="top-center"
          autoClose={10000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </LocationProvider>
    </CookiesProvider>
  );
}

export default App;

import React from "react";
import { RouteComponentProps } from "@reach/router";
import axios from "axios";
import clsx from "clsx";


const TutoringPage: React.FC<RouteComponentProps> = () => {

  const [data, setData] = React.useState([]);
  const [blocTeacher, setBlcoTeacher] = React.useState(null);
  const [blocFamily, setBlocFamily] = React.useState(null);

  const [data3, setData3] = React.useState(null);

  const [blocks, setSection] = React.useState([]);
  const [refs, setRefs] = React.useState([]);
  const [skills, setSkills] = React.useState(null);

  const [teachers, setTeachers] = React.useState([]);


  React.useEffect(() => {
    axios('/api/v1/page-covers?page=tutoring_home')
      .then(res => {
        setData(res.data)
      })

    axios('/api/v1/tutoring/page-text/1')
      .then(res => {
        setBlcoTeacher(res.data)
      })

    axios('/api/v1/tutoring/page-text/2')
      .then(res => {
        setBlocFamily(res.data)
      })

    axios('/api/v1/tutoring/page-text/10')
      .then(res => {
        setData3(res.data)
      });

    axios('/api/v1/articles/tutoring_section')
      .then(res => {
        setSection(res.data)
      });

    axios('/api/v1/tutoring/references')
      .then(res => {
        setRefs(res.data)
      });
    axios('/api/v1/tutoring/professors')
      .then(res => {
        setTeachers(res.data)
      });

    axios('/api/v1/tutoring/skills')
      .then(res => {
        setSkills(res.data)
      });
  }, []);

  return (
    <>
      <link rel="stylesheet" href="/css/test/css/style.css" type="text/css" media="all" />
      <div>
        {
          data.length > 0 &&
          <aside id="fh5co-hero" className="tutoring">
            <div className="flexslider">
              <ul className="slides tutoring">
                {
                  data.map((item, key) =>
                    <li key={key} style={{ backgroundImage: `url(${item.cover_image})` }}>
                      <div className="overlay-gradient"></div>
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12 col-md-offset-2 slider-text">
                            <div className="slider-text-inner">
                              <h1>{item.name}</h1>
                              <p style={{ color: "#fff" }}>
                                {item.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                }
              </ul>
            </div>
          </aside>
        }
      </div>


      {/* <!-- banner bottom grids --> */}
      <section className="about-bottom" id="services" style={{ marginTop: data.length === 0 ? "0" : "" }}>
        <div className="container pb-lg-4">
          <div className="row bg-colors text-center">
            {
              blocks.map(item =>
                <div className="col-md-4 service-subgrids">
                  <a href={"/soutien-scolaire/" + item?.slug}>
                    <div className="w3ls-about-grid py-lg-5 py-md-4 py-5 px-3">
                      <h4 className="text-wh font-weight-bold mb-3">{item?.name}</h4>
                      <p className="text">{item?.description}</p>
                    </div>
                  </a>
                </div>
              )
            }
          </div>
        </div>
      </section>
      {/* <!-- //banner bottom grids --> */}

      {/* <!-- about --> */}
      <section className="w3ls-bnrbtm py-5" id="about">
        <div className="container py-xl-5 py-lg-3">
          <div className="row">
            <div className="col-lg-6 pr-xl-5">
              <h3 className="text-bl font-weight-bold mt-3 mb-5">{blocTeacher?.name}</h3>
              <a href="/soutien-scolaire/enseignant">
                <h4 className="text-colors let-spa">{blocTeacher?.subtitle}</h4>
              </a>
            </div>
            <div className="col-lg-6 pl-xl-5 mt-lg-0 mt-4 bloc-become-teacher text-center">
              <p>
                {blocTeacher?.description}
              </p>

              <div className=" text-center">
                <br />
                <a href="/auth/~t/signup" className="btn btn-primary" style={{ width: 215 }}>
                  Devenir enseignant
                </a>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-lg-6 pr-xl-5">
              <h3 className="text-bl font-weight-bold mt-3 mb-5">{blocFamily?.name}</h3>
              <a href="/soutien-scolaire/famille">
                <br />
                <h4 className="text-colors let-spa">{blocFamily?.subtitle}</h4>
              </a>
            </div>
            <div className="col-lg-6 pl-xl-5 mt-lg-0 mt-4 bloc-become-teacher swip text-center">
              <p>{blocFamily?.description}</p>
              <div className="text-center">
                <br />
                <a href="/soutien-scolaire/renseignement" className="btn btn-primary" style={{ width: 215 }}>Faire une demande</a>
              </div>
            </div>
          </div>
          {/* <!-- stats--> */}
          <div className="stats-info mt-5 pt-lg-4">
            <div className="row">
              <div className="col-lg-3 col-sm-6 stats-grid-w3-w3pvt-am">
                <div className="row">
                  <div className="col-4 icon-right-w3ls text-sm-left text-center">
                    <div className="stats-icon-w3pvt">
                      <span className="far fa-smile-o"></span>

                    </div>
                  </div>
                  <div className="col-8">
                    <p className="counter font-weight-bold text-bl">{refs[0]?.["value"]}</p>
                    <p className="text-colors mt-2">{refs[0]?.["name"]}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 stats-grid-w3-w3pvt-am mt-sm-0 mt-4">
                <div className="row">
                  <div className="col-4 icon-right-w3ls text-sm-left text-center">
                    <div className="stats-icon-w3pvt">
                      <span className="fas fa-shield-alt"></span>
                    </div>
                  </div>
                  <div className="col-8">
                    <p className="counter font-weight-bold text-bl">{refs[1]?.["value"]}</p>
                    <p className="text-colors mt-2">{refs[1]?.["name"]}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 stats-grid-w3-w3pvt-am mt-lg-0 mt-4">
                <div className="row">
                  <div className="col-4 icon-right-w3ls text-sm-left text-center">
                    <div className="stats-icon-w3pvt">
                      <span className="far fa-user"></span>
                    </div>
                  </div>
                  <div className="col-8">
                    <p className="counter font-weight-bold text-bl">{refs[2]?.["value"]}</p>
                    <p className="text-colors mt-2">{refs[2]?.["name"]}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 stats-grid-w3-w3pvt-am mt-lg-0 mt-4">
                <div className="row">
                  <div className="col-4 icon-right-w3ls text-sm-left text-center">
                    <div className="stats-icon-w3pvt">
                      <span className="far fa-thumbs-up"></span>
                    </div>
                  </div>
                  <div className="col-8">
                    <p className="counter font-weight-bold text-bl">{refs[3]?.["value"]}</p>
                    <p className="text-colors mt-2">{refs[3]?.["name"]}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- //stats --> */}
        </div>
      </section>
      {/* <!-- //about --> */}


      {/* <!-- professors --> */}
      <section className="teams text-center py-5" id="professors">
        <div className="container py-xl-5 py-lg-3">
          <h3 className="text-bl font-weight-bold mb-2">Nos professeurs</h3>
          <div className="row inner-sec-w3ls-w3pvt-aminfo pt-sm-4">
            {
              teachers.length > 0 &&
              teachers.map((teacher, i) =>
                <div
                  className={clsx("col-md-3 col-sm-6 p-0",
                    { "top-tem": i % 2 !== 0 })}>
                  <div className="team-grid text-center">
                    <div className="team-img">
                      <img className="img-fluid rounded" src={teacher.image} alt="" />
                    </div>
                    <div className="team-info">
                      <h4>{teacher.name}</h4>
                      <ul className="d-flex justify-content-center py-3 social-icons">
                        <li className="effect-soc-team1">
                          <a href={teacher.facebook}>
                            <span className="fab fa-facebook-f"></span>
                          </a>
                        </li>
                        <li className="effect-soc-team2">
                          <a href={teacher.twitter}>
                            <span className="fab fa-twitter"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </section>
      {/* <!-- //professors --> */}

      {/* <!-- who we are --> */}
      <section className="w3ls-bnrbtm py-5" id="who">
        <div className="py-xl-5 py-lg-3">
          <div className="row no-gutters">
            <div className="col-xl-6 who-left-w3pvt">
              <h3 className="text-bl font-weight-bold mt-2 mb-3">{data3?.name}</h3>
              <p>
                {data3?.description}
              </p>
              <div className="bars-main-info mt-5">
                <h3 className="text-bl font-weight-bold mb-4">{skills?.name}</h3>
                <div className="progress-one">
                  <h4 className="progress-tittle">{skills?.[0]["value"]}</h4>
                  <div className="progress-effect progress-effect-1">
                  </div>
                </div>
                <div className="progress-one two-progress my-3">
                  <h4 className="progress-tittle">{skills?.[1]["value"]}</h4>
                  <div className="progress-effect progress-effect-2 w-75">
                  </div>
                </div>
                <div className="progress-one">
                  <h4 className="progress-tittle">{skills?.[2]["value"]}</h4>
                  <div className="progress-effect progress-effect-3 w-50">
                  </div>
                </div>
              </div>

            </div>
            <div className="col-xl-6 mt-xl-0 mt-5 text-xl-right text-center">
              <img className="img-fluid rounded" src={data3?.cover_image || "images/test/images/who.jpg"} alt="" />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- //who we are --> */}
    </>
  )
}

export default TutoringPage;
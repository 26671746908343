import React from "react";
import MainLayout from "ui/Layout";
import { RouteComponentProps } from "@reach/router";
import axios from "axios";
import NavMenuTop from "./components/NavMenuTop";
import TopJobOffers from "./TopJobOffers";
import Covers from "pages/Covers";
import SearchBar from "./SearchBar";
import ArticleItem from "pages/ArticleItem";
import NewItem from "./NewItem";
import moment from "moment";
import styled from "styled-components";

const Wrapper = styled.div`
  background: #f1f1f1;
  padding: 20px;

  min-height: 90vh;

  .img-responsive {
    height: 250px;
    width: 100%;
  }
`

const Title = styled.div`
  font-size: 30px;
  color: var(--primary-color);
  font-weight: bold;
`

const Date = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  color: var(--secondary-color);
  padding: 15px 0;
`

const ArticleCover = styled.div`
  height: 250px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
`

export const Desc = styled.p`
  background-color: #fff;
  padding: 10px 10px;
  border-left: 3px solid #FF4C28;
`

interface IProps extends RouteComponentProps {
  slug?: string;
}


const Overview: React.FC<RouteComponentProps> = ({ slug }: IProps) => {

  const [sheet, setSheet] = React.useState(null);



  React.useEffect(() => {
    fetch('/api/v1/articles/joboffers/public/slug/' + slug)
      .then(res => res.json())
      .then((data) => {
        setSheet(data);
      })
      .catch(console.log)
  }, []);

  return (
    <MainLayout
      top={<NavMenuTop />}
    >
      <Covers page="joboffers_home" />

      <div className="container">
        <br />
        <SearchBar />
      </div>

      <div className="container">
        <br />
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/">Accueil</a></li>
                <li className="breadcrumb-item"><a href="/stage-et-emploi">Stage et emploi</a></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <aside id="fh5co-course">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <Wrapper>
                <Title>{sheet?.name}</Title>
                <Date>{moment(sheet?.createdAt).format('MMMM DD, YYYY')}</Date>
                {
                  sheet?.description &&
                  <Desc>{sheet?.description}</Desc>
                }

                {
                  sheet?.cover_image &&
                  <ArticleCover style={{ backgroundImage: `url(${sheet?.cover_image})` }} />
                }
                <br />
                <div dangerouslySetInnerHTML={{ __html: sheet?.presentation }}></div>
              </Wrapper>
              <br />
            </div>
            <div className="col-md-4 animate-box">
              <TopJobOffers />
            </div>
          </div>
        </div>
      </aside>

    </MainLayout>
  )
}

export default Overview;